@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Hostplan
Version:        1.0
Last change:    02.20.2016
Primary use:    Hostplan - Web Domain Hosting WHMCS HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../fonts/icomoon/style.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Ubuntu:300,400,500,700);

 
// Table of contents
@import "less-hostplan/table-of-content.less";

 
// Initialize Variables
@import "less-hostplan/variables.less";
@import "less-hostplan/mixins.less";
// Typography
@import "less-hostplan/typography.less";

// Common Styles
@import "less-hostplan/common.less";
@import "less-hostplan/extra.less";
@import "less-hostplan/overlay.less";

// Header
@import "less-hostplan/header.less";

// Nav
@import "less-hostplan/nav.less";

// Content Blocks
@import "less-hostplan/topbar.less";
@import "less-hostplan/inner-header-title.less";
@import "less-hostplan/vertical-nav.less";
@import "less-hostplan/menu-full-page.less";
@import "less-hostplan/boxed-layout.less";
@import "less-hostplan/form.less";
@import "less-hostplan/side-push-panel.less";
@import "less-hostplan/box-hover-effect.less";
@import "less-hostplan/gallery-isotope.less";
@import "less-hostplan/sliders.less";
@import "less-hostplan/home.less";
@import "less-hostplan/about.less";
@import "less-hostplan/project.less";
@import "less-hostplan/volunteer.less";
@import "less-hostplan/contact.less";
@import "less-hostplan/event.less";
@import "less-hostplan/practice-area.less";
@import "less-hostplan/services.less";
@import "less-hostplan/hosting-plans.less";
@import "less-hostplan/shop.less";
@import "less-hostplan/blog.less";

// Shortcodes
@import "less-hostplan/shortcodes.less";


// Widgets
@import "less-hostplan/widgets.less";


// Footer
@import "less-hostplan/footer.less";