/*
 * domain-price.less
 * -----------------------------------------------
*/
.d-name {
  display: block;
  line-height: 15px;
}
.pricing-ribbon h5 {
  color: #fff;
  left: -60px;
  line-height: 40px;
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  top: 15px;
  transform: rotate(-45deg);
  width: 190px;
}